<template>
    <div>
        <b-card>
            <b-row class="">
                <b-col class="col-md-12">
                    <h2 class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1">
                        {{ $t("to_do") }}
                    </h2>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <Table
                :fields="fields"
                :items="todos"
                :url="url"
                class="position-relative mt-2"
                hover
                responsive
                show-empty
                striped
            >
                <template #head()="scope">
                    <div class="text-nowrap">{{ $t(scope.label) }}</div>
                </template>
                <template #cell(created_at)="data">
                    <p style="width:90px !important;">{{ data.value.substring(10,0) }}</p>
                </template>

                <template #cell(time)="data">
                    {{ data.item.created_at.substring(11,16) }}
                </template>
                <template #cell(company_name)="data">
                    <span v-if="data.item.company && data.item.company.name !=null">{{data.item.company.name}}</span>
                    <span v-else-if="data.item.company">{{data.item.company.user[0].name}} {{data.item.company.user[0].last_name}}</span>
                    <span v-else></span>
                </template>
                <!--<template #cell(description)="data">-->
                    <!--<p style="width:180px" v-if="data.item.description">-->
                        <!--{{ $t(data.item.description) }}-->
                    <!--</p>-->
                    <!--<span v-else>-</span>-->
                <!--</template>-->
                <template #cell(company_id)="data">
                    <p class="badge badge-light-primary" style="width:55px;" v-if="data.item.invoice_id == null">
                        <span v-if="data.item.company_id">
                          C-{{ data.item.company_id }}
                        </span>
                    </p>
                    <b-link :to="'/invoice/' + data.item.invoice_id" class="badge badge-light-primary" style="width:55px;" v-else>
                        I-{{ data.item.invoice_id }}
                    </b-link>
                </template>
                <template #cell(change_status)="data">
                    <!--<p style="width:180px" class="pb-0 mb-0" v-if="data.item.invoice_id == null && data.item.company_id">-->
                    <!--<p class="pb-0 mb-0" style="width:180px" v-if="data.item.company_id">-->
                    <p class="pb-0 mb-0" style="width:180px">
                        <b-button :title="$t('set_completed')" @click="setCompleted(data.item)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 0" variant="outline-success">
                            <feather-icon icon="CheckCircleIcon"/>
                        </b-button>
                        <b-button :title="$t('set_closed')" @click="setClosed(data.item)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 0" variant="outline-danger">
                            <feather-icon icon="XIcon"/>
                        </b-button>
                        <b-button :title="$t('set_pending')" @click="setPending(data.item)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 2" variant="outline-warning">
                            <feather-icon icon="RefreshCwIcon"/>
                        </b-button>
                        <span v-else></span>
                    </p>
                    <!--<p v-else-if="!data.item.company_id ">-->
                        <!--<b-button :title="$t('send_mail')" @click="navigateToSendEmail" class="mr-1" size="sm" v-b-tooltip.hover variant="outline-danger">-->
                            <!--<feather-icon icon="MailIcon"/>-->
                        <!--</b-button>-->
                    <!--</p>-->
                </template>
                <template #cell(actions)="data">
                <template v-if="data.item.company_id">
                      <template v-if="data.item.invoice_id != null">
                          <b-button :to="'/invoice/' + data.item.invoice_id" class="mr-1" size="sm" variant="outline-dark">
                              <feather-icon icon="CheckCircleIcon"/>
                          </b-button>
                      </template>
                      <template v-else>
                          <b-button @click="redirect(data.item)" class="mr-1" size="sm" v-if="data.item.status == 0 && data.item.action != 'warning'" variant="outline-dark">
                              <feather-icon icon="CheckCircleIcon"/>
                          </b-button>
                          <p class="pb-0 mb-0" style="width:120px" v-if="data.item.status == 0 && data.item.action == 'warning'">
                              <b-button :title="$t('settle_invoice')" @click="settleInvoice(data.item)" class="mr-1" size="sm" v-b-tooltip.hover variant="outline-warning">
                                  <feather-icon icon="CheckCircleIcon"/>
                              </b-button>

                              <b-button :title="$t('trigger_warning')" @click="triggerWarning(data.item)" class="mr-1" id="trigger_warning_btn" size="sm" v-b-tooltip.hover variant="outline-success">
                                  <feather-icon icon="InfoIcon"/>
                              </b-button>
                          </p>
                      </template>
                  </template>
                    <template v-else>
                        <b-button @click="navigateToSendEmail" class="mr-1" size="sm" variant="outline-dark">
                            <feather-icon icon="CheckCircleIcon"/>
                        </b-button>
                    </template>

                    <span v-else></span>
                </template>
                <template #cell(status)="data">
                    <span v-if="data.value == 0">{{ $t("pending") }} </span>
                    <span v-if="data.value == 1">{{ $t("completed") }}</span>
                    <span v-if="data.value == 2">{{ $t("closed") }}</span>
                </template>
            </Table>
        </b-card>
    </div>
</template>

<script>
  import {BButton, BCol, BFormGroup, BFormInput, BLink, BPagination, BRow, BTable, BTh, BTr,} from "bootstrap-vue";
  import CustomLoader from "@/components/Common/CustomLoader";
  import Table from "@/components/Common/Table2";
  import Ripple from "vue-ripple-directive";

  export default {
    components: {
      Table,
      BTable,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BPagination,
      BTr,
      BTh,
      BButton,
      BLink,
      CustomLoader,
    },
    directives: {
      Ripple,
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    data() {
      return {
        page: 1,
        totalItems: 0,
        url: '/task/todo',
        todos: null,
        fields: [
          {
            key: "created_at",
            label: "Date",
          },
          {
            key: "time",
            label: "Time",
          },
          {
            key: "company_id",
            label: "ID",
          },
          {
            key: "company_name",
            label: "Company Name",
          },
          {
            key: "description",
            label: "description",
          },
          {
            key: "status",
            label: "status",
          },
          {
            key: "actions",
            label: "to_do",
          },
          {
            key: "change_status",
            label: "change_status",
          },

        ],
      };
    },
    created() {
      this.getToDos();
    },
    methods: {

      navigateToSendEmail() {
        this.$router.push('/send/email');
      },

      getToDos() {
        this.$http
            .post(`/task/todo?page=` + this.page)
            .then((res) => {
              if (res) {
                this.todos = res.data.value.data;
                this.totalItems = res.data.value.total;
                this.page = res.data.value.current_page;

                console.log(this.todos)

              }
            });
      },
      redirect(item) {
        if (item.action == 'transactions' && item.company.user) {
          this.$router.push(`/investor/${item.company.user[0].id}#transactions`)
        } else if (['activate_invoice', 'debtor_confirmation', 'settle_reverse_invoice'].includes(item.action)) {
          let invoice = JSON.parse(item.data);
          this.$router.push(`/invoice/${invoice.invoice_id}`);
        } else if (item.action == 'verify_user_answers_from_sumsub' && item.company.user) {
          this.$router.push(`/investor/${item.company.user[0].id}`);
        }else if(item.action == 'check_debtor_score') {
          this.$router.push(`/company/${item.company.id}`);
        }else if(item.action == "currency_exchange") {
          this.$router.push(`/transaction-currency`);
        }
      },
      setClosed(item) {
        this.$http
            .post(`/task/todo/${item.id}/closed`)
            .then((res) => {
              if (res) {
                this.showSuccessMessage();
              }
            });
      },
      setPending(item) {
        this.$http
            .post(`/task/todo/${item.id}/pending`)
            .then((res) => {
              if (res) {
                this.showSuccessMessage();
              }
            });
      },
      setCompleted(item) {
        this.$http
            .post(`/task/todo/${item.id}/finished`)
            .then((res) => {
              if (res) {
                this.showSuccessMessage();
              }
            });
      },
      triggerWarning(item) {
        document.getElementById('trigger_warning_btn').disabled = true
        let invoice = JSON.parse(item.data);
        this.$http
            .post(`/invoice/admin/trigger-warning/${invoice.invoice_id}/${item.id}`)
            .then((res) => {
              if (res) {
                this.showSuccessMessage();
              }
            });
      },
      settleInvoice(item) {
        let invoice = JSON.parse(item.data);
        this.$http
            .post(`/invoice/admin/changeStatus/${invoice.invoice_id}`, {
              status: 11,
            })
            .then((res) => {
              if (res) {
                this.setCompleted(item);
              }
            });
      },
      showSuccessMessage() {
        let text = this.$t("success");
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 100);

      },
    },
  };
</script>

<style scoped>
</style>
